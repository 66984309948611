import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Spinner } from 'app/components/Common/Spinner'
import { Rumble } from 'app/components/Icons'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as SlideProps, Slide } from './Slide'

const AUTOPLAY_TIMER = 5000

export interface Props {
  slides: SlideProps[]
}

export const HeroAdv = memo(function HeroAdv({ slides }: Props) {
  if (slides.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [opacities, setOpacities] = useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: false,
    slides: slides.length,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      slides.length > 1 ? setOpacities(newOpacities) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)

    if (!slides.some((s) => s['videoURL'] !== undefined)) {
      timer.current = setInterval(() => {
        if (instanceRef) {
          instanceRef.current?.next()
        }
      }, AUTOPLAY_TIMER)
    }

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Spinner variant="simple" />
      <Slider ref={sliderRef}>
        {uniq(slides).map((item, index) => (
          <Slide
            key={index}
            isActive={currentSlide === index ? true : false}
            style={
              index !== 0
                ? {
                    opacity: opacities[index],
                  }
                : { opacity: 1 }
            }
            {...item}
          />
        ))}
      </Slider>

      {slides.length > 1 ? (
        <Dots>
          {slides.map((_item, index) => (
            <Dot
              key={index}
              className={currentSlide === index ? 'active' : undefined}
              onClick={() => {
                instanceRef.current?.moveToIdx(index)
              }}
            >
              <Rumble />
            </Dot>
          ))}
        </Dots>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  height: 100vh;
  max-height: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 33.4vh;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.8)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  &:before,
  &:after {
    content: '';
    width: 100%;
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    z-index: 1;
  }
  &:before {
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.2;
    top: 0;
  }
  &:after {
    height: 33.4vh;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.65)}
    );
    bottom: 0;
  }
`

const Dots = styled.section`
  position: absolute;
  top: 50%;
  right: 4.167vw;
  transform: translateY(-50%);
  z-index: 4;

  @media (max-width: 1023px) {
    display: flex;
    top: auto;
    right: auto;
    left: 50%;
    bottom: 5.625rem;
    transform: translateX(-50%);
  }
`

const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin: 1.875rem 0;
  position: relative;
  &.active {
    svg {
      fill: ${({ theme }) => theme.colors.variants.neutralLight4};
      stroke: none;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1.5;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1023px) {
    margin: 0 1.3125rem 0 0;

    &:last-of-type {
      margin: 0;
    }
  }
`
